






























































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { copyToClipboard, Notify } from "quasar";
import axios from "axios";

interface dataBeneficio {
  id: number;
  nombre: string;
  descripcion: string;
  condiciones: string;
  descuento: number;
  cupo_max: number;
  fecha_termino: string;
  id_lector: number;
  tipo: number;
  tipo_vecino: number;
  usos_maximos: number;
  visible_app: number;
}

interface dataComercio {
  descripcion_corta: string;
  direccion: string;
  email: string;
  foto: string;
  logo: string;
  id: number;
  username: string;
  web: string;
  facebook: string;
  instagram: string;
  twitter: string;
  beneficios: Array<dataBeneficio>;
}
@Component({
  components: {},
  meta: {
    // sets document title
    title: "Tarjeta Tu Puente",
    // optional; sets final title as "Index Page - My Website", useful for multiple level meta
    //titleTemplate: title => `${title} - My Website`,

    // meta tags
    meta: {
      description: {
        name: "description",
        content: "Conoce las novedades de Tarjeta Tu Puente.",
      },
      keywords: { name: "keywords", content: "Puente" },
      equiv: {
        "http-equiv": "Content-Type",
        content: "text/html; charset=UTF-8",
      },
    },
  },
})
export default class Beneficio extends Vue {
  private win = window;
  private base = window.location;
  private bannerSlide = "1";
  private horizontalPercentageInteres = 0;
  private nombreWeb: any = "";
  private comercio: dataComercio = {
    descripcion_corta: "",
    direccion: "",
    email: "",
    foto: "",
    logo: "",
    id: 0,
    username: "",
    web: "",
    facebook: "",
    instagram: "",
    twitter: "",
    beneficios: [],
  };
  private idLector: any = "";

  private mounted() {
    if (
      this.$router.currentRoute.params.nombre &&
      this.$router.currentRoute.params.nombre != ""
    ) {
      this.nombreWeb = this.$router.currentRoute.params.nombre;
      this.getInfoBeneficos();
    }
  }

  private getInfoBeneficos() {
    this.$q.loading.show({
      delay: 100, // ms
    });
    this.$axios
      .get("/listado/beneficio?nombre_web=" + this.nombreWeb)
      .then((res: any) => {
        this.comercio = res.data;
        console.log(this.comercio);
        if (this.comercio.foto != null) {
          this.comercio.foto =
            " https://d1rofaen6s22p4.cloudfront.net/" + this.comercio.foto;
        }
        if (this.comercio.logo != null) {
          this.comercio.logo =
            " https://d1rofaen6s22p4.cloudfront.net/" + this.comercio.logo;
        }
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        console.log(err);
        this.$q.loading.hide();
      });
  }

  private irCategoria(idCategoria: any, nombreWeb: any) {
    if (
      this.$router.currentRoute.name == "Comercio" &&
      this.$router.currentRoute.params.nombre == nombreWeb
    ) {
      this.$router.go(0);
    } else {
      this.$router
        .push({
          name: "Comercio",
          params: { nombre: nombreWeb },
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  private irGoogleMaps(data: any) {
    window.open("https://www.google.com/maps/search/" + data);
  }

  private irWeb(data: any) {
    window.open(data);
  }

  private irEmail(mail: string) {
    window.open("mailto:" + mail);
  }

  private llamar(data: any) {
    const numero = data;
    window.open("tel:" + numero);
  }

  private copiarClipboard(texto: any) {
    copyToClipboard(texto)
      .then(() => {
        Notify.create({
          color: "positive",
          message: "Se ha copiado el Número de Teléfono.",
          icon: "report_problem",
          position: "top",
          timeout: 2500,
        });
        return;
      })
      .catch(() => {
        Notify.create({
          color: "negative",
          message: "Ha ocurrido un problema. Por favor, inténtelo de nuevo.",
          icon: "report_problem",
          position: "top",
          timeout: 2500,
        });
        return;
      });
  }
  private scrollAreaFunctionInteres(info: any) {
    this.horizontalPercentageInteres = info.horizontalPercentage;
  }
  private scroll(direccion: string) {
    const size = (this.$refs.scrollInteres as any).scrollSize;
    const position = (this.$refs.scrollInteres as any).scrollPosition;

    if (direccion == "derecha") {
      if (this.horizontalPercentageInteres >= 1) {
        (this.$refs.scrollInteres as any).setScrollPosition(0, 300);
      } else {
        (this.$refs.scrollInteres as any).setScrollPosition(
          position + 316,
          300
        );
      }
    } else {
      if (position - 316 < 0) {
        if (position > 0) {
          (this.$refs.scrollInteres as any).setScrollPosition(0, 300);
        } else {
          (this.$refs.scrollInteres as any).setScrollPosition(size, 300);
        }
      } else {
        (this.$refs.scrollInteres as any).setScrollPosition(
          position - 316,
          300
        );
      }
    }
  }

  private formatFechaTermino(fecha_termino: any) {
    var anho_termino = fecha_termino.substring(0, 4);
    var mes_termino: any = parseInt(fecha_termino.substring(5, 7), 10);
    var dia_termino = fecha_termino.substring(8, 10);

    if (mes_termino == 1) {
      mes_termino = "ENERO";
    } else if (mes_termino == 2) {
      mes_termino = "FEBRERO";
    } else if (mes_termino == 3) {
      mes_termino = "MARZO";
    } else if (mes_termino == 4) {
      mes_termino = "ABRIL";
    } else if (mes_termino == 5) {
      mes_termino = "MAYO";
    } else if (mes_termino == 6) {
      mes_termino = "JUNIO";
    } else if (mes_termino == 7) {
      mes_termino = "JULIO";
    } else if (mes_termino == 8) {
      mes_termino = "AGOSTO";
    } else if (mes_termino == 9) {
      mes_termino = "SEPTIEMBRE";
    } else if (mes_termino == 10) {
      mes_termino = "OCTUBRE";
    } else if (mes_termino == 11) {
      mes_termino = "NOVIEMBRE";
    } else {
      mes_termino = "DICIEMBRE";
    }

    return dia_termino + " DE " + mes_termino + " DEL " + anho_termino;
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home",staticStyle:{"font-family":"Montserrat"}},[_c('div',{staticClass:"row flex-center"},[_c('div',{staticClass:"row col-12 justify-center",staticStyle:{"max-width":"1440px !important","margin-left":"auto","margin-right":"auto","font-family":"Montserrat","margin-bottom":"70px"}},[_c('div',{staticClass:"q-pa-md col-12"},[(_vm.comercio.logo)?_c('q-card',{staticClass:"row col-12 q-mt-lg",staticStyle:{"border-radius":"15px"},attrs:{"flat":""}},[_c('q-card-section',{staticClass:"q-pa-none"},[_c('div',{staticClass:"row col-12"},[_c('div',{staticClass:"col-12 col-md-7",style:('border-radius: 15px; ' +
                    (_vm.$q.screen.xs || _vm.$q.screen.sm
                      ? 'height:200px;'
                      : 'height:500px;'))},[_c('img',{staticClass:"img-fluid",style:(_vm.$q.screen.xs || _vm.$q.screen.sm
                      ? 'border-radius: 15px 15px 0px 0px; height:100%;'
                      : 'border-radius: 15px 0px 0px 15px; height:100%;'),attrs:{"src":_vm.comercio.logo}})]),_c('div',{class:'row justify-center col-12 col-md-5 ' +
                    (_vm.$q.screen.xs ? 'q-pa-md' : 'q-px-xl q-py-md')},[(_vm.comercio.foto)?_c('div',{staticClass:"row col-12 items-center",staticStyle:{"width":"135px","height":"135px"}},[_c('img',{staticStyle:{"max-height":"135px","max-width":"135px"},attrs:{"src":_vm.comercio.foto,"alt":"logo convenio"}})]):_vm._e(),_c('p',{staticClass:"text-bold text-center text-h6 col-12 q-my-md",staticStyle:{"color":"#FA5811"}},[_vm._v(" "+_vm._s(_vm.comercio.username)+" ")]),_c('div',{staticClass:"col-12 q-my-md"},[_c('q-scroll-area',{staticStyle:{"height":"65px","width":"100%"},attrs:{"visible":"visible"}},[_c('p',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.comercio.descripcion_corta)+" ")])])],1),_c('div',{staticClass:"col-12 row"},[(_vm.comercio.telefono)?_c('div',{staticClass:"q-pt-sm col-12"},[_c('q-icon',{attrs:{"size":"sm","name":"phone","color":"primary"}}),_c('span',{staticClass:"q-ml-sm text-subtitle1"},[_vm._v(_vm._s(_vm.comercio.telefono))])],1):_vm._e(),(_vm.comercio.email_responsable)?_c('div',{staticClass:"q-pt-sm col-12"},[_c('q-icon',{attrs:{"size":"sm","name":"email","color":"primary"}}),_c('span',{staticClass:"q-ml-sm text-subtitle1"},[_vm._v(_vm._s(_vm.comercio.email_responsable))])],1):_vm._e(),(_vm.comercio.direccion)?_c('div',{staticClass:"q-pt-sm col-12"},[_c('q-icon',{attrs:{"size":"sm","name":"place","color":"primary"}}),_c('span',{staticClass:"q-ml-sm text-subtitle1"},[_vm._v(_vm._s(_vm.comercio.direccion))])],1):_vm._e(),_c('div',{class:_vm.$q.screen.xs
                        ? 'col-12 row justify-center q-my-md'
                        : 'col-12 row justify-start q-my-sm'},[(_vm.comercio.web && _vm.comercio.web != null)?_c('div',{class:_vm.$q.screen.xs
                          ? 'col-xs-1 col-sm-1 col-md-1 margin-icon-mobile q-ma-md'
                          : 'col-xs-1 col-sm-1 col-md-1 margin-icon-mobile q-ma-sm'},[(_vm.comercio.web && _vm.comercio.web != null)?_c('q-btn',{staticClass:"icon",attrs:{"round":"","icon":"language"},on:{"click":function($event){return _vm.irWeb(_vm.comercio.web)}}},[_c('q-tooltip',[_vm._v(" Página Web ")])],1):_vm._e()],1):_vm._e(),(_vm.comercio.telefono && _vm.comercio.telefono != null)?_c('div',{class:_vm.$q.screen.xs
                          ? 'col-xs-1 col-sm-1 col-md-1 margin-icon-mobile q-ma-md'
                          : 'col-xs-1 col-sm-1 col-md-1 margin-icon-mobile q-ma-sm'},[(_vm.comercio.telefono && _vm.comercio.telefono != null)?_c('q-btn',{staticClass:"icon",attrs:{"round":"","icon":"phone"},on:{"click":function($event){return _vm.llamar(_vm.comercio.telefono)}}},[_c('q-tooltip',[_vm._v(" Teléfono ")])],1):_vm._e()],1):_vm._e(),(
                        _vm.comercio.email_responsable &&
                          _vm.comercio.email_responsable != null
                      )?_c('div',{class:_vm.$q.screen.xs
                          ? 'col-xs-1 col-sm-1 col-md-1 margin-icon-mobile q-ma-md'
                          : 'col-xs-1 col-sm-1 col-md-1 margin-icon-mobile q-ma-sm'},[(
                          _vm.comercio.email_responsable &&
                            _vm.comercio.email_responsable != null
                        )?_c('q-btn',{staticClass:"icon",attrs:{"round":"","icon":"mail"},on:{"click":function($event){return _vm.irEmail(_vm.comercio.email_responsable)}}},[_c('q-tooltip',[_vm._v(" Correo Electrónico ")])],1):_vm._e()],1):_vm._e(),(
                        _vm.comercio.direccion != 'TIENDA ONLINE' &&
                          _vm.comercio.direccion
                      )?_c('div',{class:_vm.$q.screen.xs
                          ? 'col-xs-1 col-sm-1 col-md-1 margin-icon-mobile q-ma-md'
                          : 'col-xs-1 col-sm-1 col-md-1 margin-icon-mobile q-ma-sm'},[(
                          _vm.comercio.direccion != 'TIENDA ONLINE' &&
                            _vm.comercio.direccion
                        )?_c('q-btn',{staticClass:"icon",attrs:{"round":"","icon":"place"},on:{"click":function($event){return _vm.irGoogleMaps(_vm.comercio.direccion)}}},[_c('q-tooltip',[_vm._v(" Dirección ")])],1):_vm._e()],1):_vm._e(),(_vm.comercio.facebook && _vm.comercio.facebook != null)?_c('div',{class:_vm.$q.screen.xs
                          ? 'col-xs-1 col-sm-1 col-md-1 margin-icon-mobile q-ma-md'
                          : 'col-xs-1 col-sm-1 col-md-1 margin-icon-mobile q-ma-sm'},[(_vm.comercio.facebook && _vm.comercio.facebook != null)?_c('q-btn',{staticClass:"icon",attrs:{"round":"","icon":"ion-logo-facebook"},on:{"click":function($event){return _vm.irWeb(_vm.comercio.facebook)}}},[_c('q-tooltip',[_vm._v(" Facebook ")])],1):_vm._e()],1):_vm._e(),(_vm.comercio.instagram && _vm.comercio.instagram != null)?_c('div',{class:_vm.$q.screen.xs
                          ? 'col-xs-1 col-sm-1 col-md-1 margin-icon-mobile q-ma-md'
                          : 'col-xs-1 col-sm-1 col-md-1 margin-icon-mobile q-ma-sm'},[(
                          _vm.comercio.instagram && _vm.comercio.instagram != null
                        )?_c('q-btn',{staticClass:"icon",attrs:{"round":"","icon":"ion-logo-instagram"},on:{"click":function($event){return _vm.irWeb(_vm.comercio.instagram)}}},[_c('q-tooltip',[_vm._v(" Instagram ")])],1):_vm._e()],1):_vm._e(),(_vm.comercio.twitter && _vm.comercio.twitter != null)?_c('div',{class:_vm.$q.screen.xs
                          ? 'col-xs-1 col-sm-1 col-md-1 margin-icon-mobile q-ma-md'
                          : 'col-xs-1 col-sm-1 col-md-1 margin-icon-mobile q-ma-sm'},[(_vm.comercio.twitter && _vm.comercio.twitter != null)?_c('q-btn',{staticClass:"icon",attrs:{"round":""},on:{"click":function($event){return _vm.irWeb(_vm.comercio.twitter)}}},[_c('q-tooltip',[_vm._v(" X-twitter ")]),_c('img',{staticStyle:{"width":"15px","height":"15px"},attrs:{"src":require("@/assets/iconos/twitter.png")}})],1):_vm._e()],1):_vm._e()])])])])])],1):_c('q-card',{class:'row col-12  q-mt-lg ' + (_vm.$q.screen.xs ? 'q-pa-md' : 'q-pa-xl'),staticStyle:{"border-radius":"15px"},attrs:{"flat":""}},[_c('q-card-section',{staticClass:"row col-12 q-pa-none"},[_c('q-card-section',{class:'col-12 col-md-6 row justify-center ' +
                  (_vm.$q.screen.xs || _vm.$q.screen.sm ? 'q-pb-none' : '')},[(_vm.comercio.foto)?_c('div',{staticClass:"row col-12 items-center",staticStyle:{"width":"150px","height":"150px"}},[_c('img',{staticStyle:{"max-height":"150px","max-width":"150px"},attrs:{"src":_vm.comercio.foto,"alt":"logo"}})]):_vm._e(),_c('div',{staticClass:"row justify-center col-12"},[_c('p',{staticClass:"text-bold text-h6 q-py-md",staticStyle:{"color":"#FA5811"}},[_vm._v(" "+_vm._s(_vm.comercio.username)+" ")])]),_c('q-scroll-area',{staticStyle:{"height":"60px","width":"100%"},attrs:{"visible":"visible"}},[_c('p',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.comercio.descripcion_corta)+" ")])])],1),_c('q-separator',{staticClass:"q-mx-lg xs-hide sm-hide",attrs:{"vertical":"","inset":""}}),_c('q-card-section',{class:'col-12 col-md-5 row content-center q-pa-none ' +
                  (_vm.$q.screen.xs || _vm.$q.screen.sm ? 'q-pt-sm' : '')},[(_vm.comercio.telefono)?_c('div',{staticClass:"q-pt-sm col-12 q-pl-sm"},[_c('q-icon',{attrs:{"size":"sm","name":"phone","color":"primary"}}),_c('span',{staticClass:"q-ml-sm text-subtitle1"},[_vm._v(_vm._s(_vm.comercio.telefono))])],1):_vm._e(),(_vm.comercio.email_responsable)?_c('div',{staticClass:"q-pt-sm col-12 q-pl-sm cont_email"},[_c('q-icon',{attrs:{"size":"sm","name":"email","color":"primary"}}),_c('span',{staticClass:"q-ml-sm text-subtitle1"},[_vm._v(_vm._s(_vm.comercio.email_responsable))])],1):_vm._e(),(_vm.comercio.direccion)?_c('div',{staticClass:"q-pt-sm col-12 q-pl-sm"},[_c('q-icon',{attrs:{"size":"sm","name":"place","color":"primary"}}),_c('span',{staticClass:"q-ml-sm text-subtitle1"},[_vm._v(_vm._s(_vm.comercio.direccion))])],1):_vm._e(),_c('div',{class:_vm.$q.screen.xs
                        ? 'col-12 row justify-center q-my-md'
                        : 'col-12 row justify-start q-my-sm'},[(_vm.comercio.web && _vm.comercio.web != null)?_c('div',{class:_vm.$q.screen.xs
                          ? 'col-xs-1 col-sm-1 col-md-1 margin-icon-mobile q-ma-md'
                          : 'col-xs-1 col-sm-1 col-md-1 margin-icon-mobile q-ma-sm'},[(_vm.comercio.web && _vm.comercio.web != null)?_c('q-btn',{staticClass:"icon",attrs:{"round":"","icon":"language"},on:{"click":function($event){return _vm.irWeb(_vm.comercio.web)}}},[_c('q-tooltip',[_vm._v(" Página Web ")])],1):_vm._e()],1):_vm._e(),(_vm.comercio.telefono && _vm.comercio.telefono != null)?_c('div',{class:_vm.$q.screen.xs
                          ? 'col-xs-1 col-sm-1 col-md-1 margin-icon-mobile q-ma-md'
                          : 'col-xs-1 col-sm-1 col-md-1 margin-icon-mobile q-ma-sm'},[(_vm.comercio.telefono && _vm.comercio.telefono != null)?_c('q-btn',{staticClass:"icon",attrs:{"round":"","icon":"phone"},on:{"click":function($event){return _vm.llamar(_vm.comercio.telefono)}}},[_c('q-tooltip',[_vm._v(" Teléfono ")])],1):_vm._e()],1):_vm._e(),(
                        _vm.comercio.email_responsable &&
                          _vm.comercio.email_responsable != null
                      )?_c('div',{class:_vm.$q.screen.xs
                          ? 'col-xs-1 col-sm-1 col-md-1 margin-icon-mobile q-ma-md'
                          : 'col-xs-1 col-sm-1 col-md-1 margin-icon-mobile q-ma-sm'},[(
                          _vm.comercio.email_responsable &&
                            _vm.comercio.email_responsable != null
                        )?_c('q-btn',{staticClass:"icon",attrs:{"round":"","icon":"mail"},on:{"click":function($event){return _vm.irEmail(_vm.comercio.email_responsable)}}},[_c('q-tooltip',[_vm._v(" Correo Electrónico ")])],1):_vm._e()],1):_vm._e(),(
                        _vm.comercio.direccion != 'TIENDA ONLINE' &&
                          _vm.comercio.direccion
                      )?_c('div',{class:_vm.$q.screen.xs
                          ? 'col-xs-1 col-sm-1 col-md-1 margin-icon-mobile q-ma-md'
                          : 'col-xs-1 col-sm-1 col-md-1 margin-icon-mobile q-ma-sm'},[(
                          _vm.comercio.direccion != 'TIENDA ONLINE' &&
                            _vm.comercio.direccion
                        )?_c('q-btn',{staticClass:"icon",attrs:{"round":"","icon":"place"},on:{"click":function($event){return _vm.irGoogleMaps(_vm.comercio.direccion)}}},[_c('q-tooltip',[_vm._v(" Dirección ")])],1):_vm._e()],1):_vm._e(),(_vm.comercio.facebook && _vm.comercio.facebook != null)?_c('div',{class:_vm.$q.screen.xs
                          ? 'col-xs-1 col-sm-1 col-md-1 margin-icon-mobile q-ma-md'
                          : 'col-xs-1 col-sm-1 col-md-1 margin-icon-mobile q-ma-sm'},[(_vm.comercio.facebook && _vm.comercio.facebook != null)?_c('q-btn',{staticClass:"icon",attrs:{"round":"","icon":"ion-logo-facebook"},on:{"click":function($event){return _vm.irWeb(_vm.comercio.facebook)}}},[_c('q-tooltip',[_vm._v(" Facebook ")])],1):_vm._e()],1):_vm._e(),(_vm.comercio.instagram && _vm.comercio.instagram != null)?_c('div',{class:_vm.$q.screen.xs
                          ? 'col-xs-1 col-sm-1 col-md-1 margin-icon-mobile q-ma-md'
                          : 'col-xs-1 col-sm-1 col-md-1 margin-icon-mobile q-ma-sm'},[(
                          _vm.comercio.instagram && _vm.comercio.instagram != null
                        )?_c('q-btn',{staticClass:"icon",attrs:{"round":"","icon":"ion-logo-instagram"},on:{"click":function($event){return _vm.irWeb(_vm.comercio.instagram)}}},[_c('q-tooltip',[_vm._v(" Instagram ")])],1):_vm._e()],1):_vm._e(),(_vm.comercio.twitter && _vm.comercio.twitter != null)?_c('div',{class:_vm.$q.screen.xs
                          ? 'col-xs-1 col-sm-1 col-md-1 margin-icon-mobile q-ma-md'
                          : 'col-xs-1 col-sm-1 col-md-1 margin-icon-mobile q-ma-sm'},[(_vm.comercio.twitter && _vm.comercio.twitter != null)?_c('q-btn',{staticClass:"icon",attrs:{"round":""},on:{"click":function($event){return _vm.irWeb(_vm.comercio.twitter)}}},[_c('q-tooltip',[_vm._v(" X-twitter ")]),_c('img',{staticStyle:{"width":"15px","height":"15px"},attrs:{"src":require("@/assets/iconos/twitter.png")}})],1):_vm._e()],1):_vm._e()])])],1)],1)],1),_c('div',{staticClass:"row justify-center col-12 q-my-lg"},[(_vm.comercio.beneficios.length > 0)?_c('div',{staticClass:"row justify-center col-12 q-my-lg"},[_c('label',{staticClass:"page_title"},[_vm._v("Descuentos")])]):_vm._e(),_vm._l((_vm.comercio.beneficios),function(item){return _c('div',{key:item.id,staticClass:"col-11 col-md-8 q-pa-sm"},[_c('q-list',{staticClass:"rounded-borders",attrs:{"bordered":""}},[_c('q-expansion-item',{staticStyle:{"font-size":"18px","color":"#7a7a7a !important"},attrs:{"switch-toggle-side":"","expand-separator":"","label":item.nombre,"header-class":"text-grey-8 bg-grey-4","expand-icon-class":"text-amber-10"}},[_c('q-card',[_c('q-card-section',[_c('div',{staticClass:"row col-12"},[_c('p',[_vm._v(_vm._s(item.descripcion))])]),_c('div',{staticClass:"row col-12"},[_c('p',{staticClass:"text-amber-10 text_semibold"},[_vm._v(" VÁLIDA HASTA "+_vm._s(_vm.formatFechaTermino(item.fechaTermino))+" ")])]),_c('div',{staticClass:"row col-12"},[_c('label',{staticClass:"text_semibold"},[_vm._v("CONDICIONES")])]),_c('div',{staticClass:"row col-12"},[_c('p',[_vm._v(_vm._s(item.condiciones))])])])],1)],1)],1)],1)})],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }